<template>
    <v-app-bar
        id="app-bar"
        app
        clipped-left
        flat
        color="primaryBackgroundColor"
        class="pl-3"
    >
        <v-app-bar-nav-icon
            @click.stop="$eventBus.$emit('toogle-navigation')"
        ></v-app-bar-nav-icon>
        <v-list class="pt-0 d-flex align-center" style="height: 100%">
            <v-list-item class="pt-0">
                <v-list-item-avatar class="pt-0" tile>
                    <v-img :src="require('@/assets/img/logo-gcf.svg')"></v-img>
                </v-list-item-avatar>
            </v-list-item>
        </v-list>
        <v-spacer />
        <!-- <v-switch v-model="$vuetify.theme.dark" inset hide-details label="Dark Mode"></v-switch> -->
        <div class="font-weight-black font-size-14 mr-3">{{ welcome }}</div>
        <!-- <v-btn class="ml-3 mr-2" outlined fab icon small>
            <v-icon>mdi-email-outline</v-icon>
        </v-btn> -->
        <v-menu offset-y nudge-bottom="5" min-width="160px">
            <template v-slot:activator="{ on }">
                <v-btn class="ml-3 mr-2" v-on="on" outlined fab icon small>
                    <v-icon>person</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item dense @click="toProfile">Profile</v-list-item>
                <v-list-item dense @click="logout">Sign Out</v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
export default {
    name: "app-bar",

    data() {
        return {
            user: {},
        };
    },

    methods: {
        logout() {
            this.clearData();
        },
        clearData() {
            this.$store.dispatch("clearState");
            this.$cookies.removeValue("token");
            this.$cookies.removeValue("user");
            this.$api.main.setAuthorization(undefined);
            this.$router.push({ name: "login" });
        },
        toProfile() {
            this.$router.push({ name: "profile" });
        },
    },

    computed: {
        welcome() {
            return `Welcome, ${this.user.first_name} ${this.user.last_name}!`;
        },
    },

    mounted() {
        this.user = this.$cookies.getValue("user");
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#app-bar {
}
</style>
