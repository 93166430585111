<template>
  <div id="main-view">
    <progress-bar/>
    <main-notifications/>
    <navigation/>
    <app-bar/>
    <v-main class="fill-height">
      <router-view class="content" />
    </v-main>
  </div>
</template>

<script>
  import progressBar from '@/components/Core/ProgressBar';
  import mainNotifications from '@/components/Core/MainNotifications';
  import navigation from '@/components/Core/Navigation';
  import appBar from '@/components/Core/AppBar';

  export default {
    name: 'main-view',
    components: {
      progressBar,
      mainNotifications,
      navigation,
      appBar
    },
    data() {
      return {};
    },
    watch: {
      $route() {
        // console.log(to, from);
        window.scrollTo(0, 0);
      }
    },
    methods: {
      initiate() {
        window.scrollTo(0, 0);
      }
    },
    mounted() {
      this.initiate();
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  #main-view {
    .content {
      flex: 1 1 auto;
      backface-visibility: hidden;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
      position: relative;
    }
  }
</style>