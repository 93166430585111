<template>
  <v-fade-transition>
    <v-progress-linear v-if="(progress.loading)" id="progress-bar" indeterminate :color="((progress.status != 'initiate') ? ((progress.status == 'success') ? 'green darken-2' : 'red darken-2') : 'blue darken-2')" fixed top/>
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'progress-bar',
    data() {
        return {
            progress: {
                status: false,
                success: true
            }
        };
    },
    methods: {
      initiate() {
        this.events();
      },
      events() {
        this.$eventBus.$on('progress', (status) => {
          // console.log(status);
          clearTimeout(this.progressTimeout);
          switch (status) {
            case 'initiate':
              setTimeout(() => { this.progress = { loading: true, status }; });
              break;
            case 'stop':
              this.progress = { ...this.progress, loading: false };
              break;
            default:
              this.progress.status = status;
              this.progressTimeout = setTimeout(() => { this.progress.loading = false; }, 2000);
          }
        });
      }
    },
    mounted() {
      this.initiate();
    },
    beforeDestroy() {
      this.$eventBus.$off('progress');
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  #progress-bar {}
</style>